/* This File is for {purpose} */

<template>
  <v-layout wrap justify-start pa-2 pa-sm-4 pa-lg-8>
    <v-flex xs12>
      <v-layout wrap justify-start py-4>
        <v-flex xs12>
          <v-card outlined class="app-border-color" rounded="lg">
            <v-layout wrap justify-start pa-4>
              <v-flex xs6 sm4 lg3>
                <span class="subtitle-2">
                  {{ $t("form.name") }}
                </span>
                <br />
                <span class="title-3">
                  {{ department.name }}
                </span>
              </v-flex>
              <v-flex xs6 sm4 lg3>
                <span class="subtitle-2">
                  {{ $t("form.phone") }}
                </span>
                <br />
                <span class="title-3"> {{ department.phone }} </span>
              </v-flex>
              <v-flex xs6 sm4 lg3 pt-4 pt-sm-0>
                <span class="subtitle-2">
                  {{ $t("form.address") }}
                </span>
                <br />
                <span class="title-3"> {{ department.address }} </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout wrap justify-start py-4>
        <v-flex xs12 pb-4 class="title-2">
          {{ $t("pages.employees.title") }}
        </v-flex>
        <v-flex xs12 md3 lg5 xl7></v-flex>
        <v-flex xs12 md9 lg7 xl5 py-2 align-self-center>
          <v-layout wrap justify-end>
            <v-flex xs6 md3 pr-2 text-right align-self-center>
              <v-select
                :label="$t('form.gender')"
                :items="GENDERS"
                item-value="value"
                item-text="label"
                outlined
                dense
                hide-details
                v-model="filter.gender"
                clearable
                @change="getEmployeesList(false)"
              ></v-select>
            </v-flex>
            <v-flex xs6 md3 pr-2 text-right align-self-center>
              <v-select
                :label="$t('form.qualification')"
                outlined
                dense
                hide-details
                :items="qualifications"
                v-model="filter.qualifications"
                item-text="name"
                item-value="id"
                clearable
                @change="getEmployeesList(false)"
              ></v-select>
            </v-flex>
            <v-flex xs6 md3 pr-2 text-right align-self-center>
              <v-text-field
                :placeholder="$t('form.search')"
                type="search"
                outlined
                dense
                hide-details
                append-icon="mdi-magnify"
                v-model="filter.search"
                @change="getEmployeesList(false)"
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <AppSimpleTable
            :headings="headings"
            :items="list"
            :pagination="pagination"
            :empty-title="
              $t('messages.info.no_items', {
                type: $t('pages.employees.assignments'),
              })
            "
            @paginate="(val) => (pagination.page = val)"
            @delete="toggleDelete"
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <DeleteConfirmation
      v-model="showDelete"
      :item="selectedItem"
      @cancel="toggleDelete"
      @submit="confirmDeleteEmployee"
    />
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Helpers from "@/libs/helper/components/helper-functions";
import { GENDERS } from "@/constants/genders";

export default {
  name: "Department",
  components: {},
  data: () => ({
    selectedItem: {},
    showDelete: false,
    GENDERS,
    filter: {
      gender: null,
      qualifications: null,
      search: null,
    },
    headings: [
      { name: "#", value: "index", style: "width:80px" },
      { name: "form.name", value: "name" },
      { name: "pages.employees.joined_date", value: "date" },
      { name: "pages.employees.total_assignments", value: "assignments" },
      {
        name: "table.actions",
        value: "action",
        style: "max-width:200px",
        options: ["view", "edit", "delete"],
        view: "employee",
        edit: "employee.edit",
      },
    ],

    pagination: {
      per_page: 25,
      page: 1,
      last_page: 1,
      total: 0,
      from: 0,
      to: 0,
    },
  }),
  computed: {
    ...mapGetters({
      getDepartment: "app/getDepartment",
      getEmployees: "hospital/getEmployees",
      qualifications: "app/getQualifications",
    }),
    department() {
      const breadCrumbs = [
        {
          name: "departments",
          text: "pages.departments.title",
        },
        {
          name: this.$route.name,
          params: this.$route.params,
          text: this.getDepartment?.name,
        },
      ];
      this.$store.commit("app/setBreadCrumbs", breadCrumbs);
      return this.getDepartment;
    },
    list() {
      return this.getEmployees.map((itm, index) => {
        return {
          index: this.pagination.from + index,
          id: itm.id,
          name: itm.first_name + " " + itm.last_name,
          date: this.$moment(itm.created_at).format("DD MMM YYYY"),
          assignments: itm.total_assignments,
        };
      });
    },
  },
  watch: {
    "pagination.page"() {
      this.getEmployeesList(true);
    },
  },
  created() {
    this.filter.gender = parseInt(this.$route.query.gender) || null;
    this.filter.qualifications =
      parseInt(this.$route.query.qualifications) || null;
    this.filter.search = this.$route.query.search || null;
    this.pagination.page = parseInt(this.$route.query.page) || 1;
    this.getDepartmentDetails(this.$route.params.id);
    this.getEmployeesList(true);
  },
  methods: {
    ...mapActions({
      getDepartmentDetails: "app/getDepartment",
      getEmployeeList: "hospital/getEmployeeList",
      deleteEmployee: "hospital/deleteEmployee",
    }),
    updateQueryParams(pagination = false) {
      if (!pagination) this.pagination.page = 1;

      this.$router.replace({
        query: {
          gender: this.filter.gender,
          qualifications: this.filter.qualifications,
          search: this.filter.search,
          page: this.pagination.page,
        },
      });
    },
    getEmployeesList(paginate = false) {
      this.updateQueryParams(paginate);
      const payload = {
        department_id: this.$route.params.id,
        is_active: true,
        ...this.pagination,
        ...this.filter,
      };
      this.getEmployeeList(payload).then((res) => {
        this.pagination = Helpers.setPagination(res);
      });
    },
    toggleDelete(val) {
      if (val) this.selectedItem = val;
      else this.selectedItem = {};
      this.showDelete = !this.showDelete;
    },
    confirmDeleteEmployee() {
      this.deleteEmployee(this.selectedItem.id).then(() => {
        this.toggleDelete();
        this.getEmployeesList();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn-toggle {
  border-radius: 7px;
}
.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: #c0cbe0 !important;
}
.v-btn--active::before,
.v-btn--active:hover::before,
.v-btn--active {
  opacity: 1 !important;
  background-color: #dce8ff;
}
.v-btn-toggle > .v-btn.v-btn {
  opacity: 1;
}
.v-btn:focus::before {
  opacity: 0;
}
.v-btn:hover::before {
  background-color: #dce8ff;
  opacity: 0.34;
}
</style>
